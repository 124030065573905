<template>
  <!--
    The view for the Sound-component.
    Used to display the themes Sounds.
  -->
  <div class="soundOverview">
    <ThemeSoundList
      ref="themeSoundList" 
      :theme-id="themeId"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :theme-id="themeId"
      :is-theme-elements="true"
      @reload="reloadLayoutElementList"
    />
  </div>
</template>

<script>
export default {
  name: "SoundOverview",
  components: {
    ThemeSoundList: () => import('@/components/VideoPostprocessing/Theme/ThemeSoundList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    themeId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadLayoutElementList () {
      this.$refs.themeSoundList.reloadThemeSounds();
    },
  }
}
</script>
